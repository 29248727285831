/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import { WidgetOptions } from '@okta/okta-signin-widget';

export type CustomWidgetOptions = WidgetOptions & {
  assets: {
    languages?: string[];
  };
};

export type OidcConfig = {
  issuer: string;
  clientId: string;
  redirectUri: string;
  scopes: string[];
};

export enum LoginType {
  TakedaId = 'takeda_id',
  DocCheck = 'doc_check',
  Unauthenticated = 'unauthenticated',
}

export type TakedaIdError = {
  invalidCountry?: boolean;
  wrongCountry?: boolean;
};

export enum TakedaIdErrorType {
  InvalidCountry = 'invalid_country',
  InvalidUser = 'invalid_user',
  RequestError = 'request_error',
  WrongCountry = 'wrong_country',
}

export enum DocCheckErrorType {
  RequestError = 'request_error',
}

export type ErrorTranslations = {
  reason: string;
  contact?: string;
};
